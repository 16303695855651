<template>
  <div style="background-color: #fff;height:500px;"><div>
    <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/c9432251838b376d973a69558be537f0.jpeg" style="width: 100%;" alt="">
  </div>
  <div class="mobile">
          <van-field v-model="phone" type="tel" :border="false" ref="phone" placeholder="请输入充值手机号码"/>
        </div>
        <div class="paybtn mt20"><van-button type="info" round block @click="onsubmit">立即兑换</van-button></div>
    </div>

    </template>
    <script>
    export default {
      data() {
        return {
          phone: ''
        }
      },
      methods:{
        onsubmit(){
          if(this.phone.length != 11){
            this.$toast('请输入正确的手机号码')
            return
          }
          this.$router.push({path:'/phonebill/pay',query:{phone:this.phone}})
        }

      }
    }
  </script>
<style scoped lang="less">
  .mobile{margin-top:24px;
    .van-cell{background:#F4F4F4;border-radius:30px;}
  }
</style>
